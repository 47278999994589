"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedQuantityEditField = RelatedQuantityEditField;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var RelatedEntityField_1 = require("@shared/data/entity/fields/RelatedEntityField");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var EditFieldContainer_1 = require("../EditFieldContainer");
var RelatedEntityEditField_1 = require("./RelatedEntityEditField");
var RelatedEntityPicker_1 = require("./RelatedEntityPicker");
var RelatedQuantityList_1 = require("./RelatedQuantityList");
var useAddNewEntityToRelatedEntityField_1 = require("./useAddNewEntityToRelatedEntityField");
var useWorkflowSupportsQuickCreate_1 = require("./useWorkflowSupportsQuickCreate");
function RelatedQuantityEditField(_a) {
    var component = _a.component, field = _a.field, updateField = _a.updateField, fields = _a.fields, error = _a.error, _b = _a.allowCreate, allowCreate = _b === void 0 ? true : _b;
    var containerRef = (0, react_1.useRef)(null);
    var _c = (0, hooks_1.useBoolean)(false), isPickerOpen = _c[0], openPicker = _c[1], closePicker = _c[2];
    var items = (0, react_1.useMemo)(function () { var _a; return (_a = field === null || field === void 0 ? void 0 : field.items) !== null && _a !== void 0 ? _a : []; }, [field]);
    var _d = (0, RelatedEntityField_1.useRelatedEntities)(field, component.workflowTemplateId), entities = _d.entities, workflowEntitiesLoading = _d.loading;
    var workflowSupportsQuickCreate = (0, useWorkflowSupportsQuickCreate_1.useWorkflowSupportsQuickCreate)({
        workflowTemplateId: component.workflowTemplateId,
    });
    var canQuickCreate = allowCreate && workflowSupportsQuickCreate;
    var _e = (0, react_1.useState)(null), openingEntity = _e[0], setOpeningEntity = _e[1];
    var onChange = (0, react_1.useCallback)(function (updatedEntities) {
        var updatedItems = updatedEntities.map(function (entity) {
            var match = items.find(function (item) { return item.id === entity.id; });
            return match !== null && match !== void 0 ? match : { id: entity.id, quantity: 1 };
        });
        updateField(component.id, new RelatedEntityField_1.RelatedEntityField(updatedItems, updatedEntities));
    }, [component.id, updateField, items]);
    var updateItemQuantity = (0, react_1.useCallback)(function (id, quantity) {
        var updatedItems = items.map(function (item) {
            return item.id === id ? __assign(__assign({}, item), { quantity: quantity }) : item;
        });
        updateField(component.id, new RelatedEntityField_1.RelatedEntityField(updatedItems, entities));
    }, [component.id, updateField, items, entities]);
    var hasEntities = !(0, lodash_1.isEmpty)(entities) || workflowEntitiesLoading;
    var showEntities = !workflowEntitiesLoading && hasEntities;
    var label = getLabelWithCountAndType(component, items);
    var isReadOnly = !!component.readonly;
    var openEntity = (0, RelatedEntityEditField_1.useOpenRelatedEntity)();
    var showClearButton = hasEntities && !isReadOnly;
    var onClear = (0, react_1.useCallback)(function () {
        onChange([]);
    }, [onChange]);
    var onItemPress = (0, react_1.useCallback)(function (entity) {
        if ((0, lodash_1.isNil)(entity)) {
            return;
        }
        setOpeningEntity(entity);
        openEntity(entity);
    }, [openEntity]);
    var onItemRemove = (0, react_1.useCallback)(function (entity) {
        if ((0, lodash_1.isNil)(entity)) {
            return;
        }
        onChange(entities.filter(function (e) { return e.id !== entity.id; }));
    }, [entities, onChange]);
    var addNewEntity = (0, useAddNewEntityToRelatedEntityField_1.useAddNewEntityToRelatedEntityField)({
        workflowTemplateId: component.workflowTemplateId,
        field: field,
        disabled: !canQuickCreate,
        onCreate: onChange,
    });
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.TouchableOpacity, { onPress: isReadOnly ? undefined : openPicker, ref: containerRef, disabled: workflowEntitiesLoading || isReadOnly },
            react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, label: label, showLabel: hasEntities, showPlaceholder: !hasEntities, onClear: showClearButton ? onClear : undefined, error: error },
                workflowEntitiesLoading && react_1.default.createElement(components_1.MBLoadingIndicator, { style: { alignItems: 'flex-start' } }),
                showEntities && (react_1.default.createElement(RelatedQuantityList_1.RelatedQuantityList, { component: component, items: items, entities: entities, openPicker: openPicker, onShowMore: openPicker, updateItemQuantity: updateItemQuantity, onItemPress: isReadOnly ? undefined : onItemPress, onItemRemove: isReadOnly ? undefined : onItemRemove, openingEntity: openingEntity })))),
        isPickerOpen && (react_1.default.createElement(RelatedEntityPicker_1.RelatedEntityPicker, { entities: entities, addNewEntity: addNewEntity, onChange: onChange, onClose: closePicker, containerRef: containerRef, component: component, fields: fields, canCreateNew: canQuickCreate }))));
}
function getLabelWithCountAndType(component, items) {
    var _a;
    var count = items.length;
    var totalQuantity = (0, lodash_1.sum)(items.map(function (item) { var _a; return (_a = item.quantity) !== null && _a !== void 0 ? _a : 0; }));
    var componentLabel = (_a = component.label) !== null && _a !== void 0 ? _a : '';
    return (0, i18n_1.i18n)('card.blocks.cardsInput.quantity.label', {
        label: componentLabel,
        totalCount: totalQuantity,
        count: count,
    });
}
