"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedQuantityList = RelatedQuantityList;
exports.AddMoreButton = AddMoreButton;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var Pill_1 = require("@shared/components/pills/Pill");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var RelatedEntityList_1 = require("./RelatedEntityList");
function RelatedQuantityList(_a) {
    var _b;
    var component = _a.component, items = _a.items, entities = _a.entities, openPicker = _a.openPicker, updateItemQuantity = _a.updateItemQuantity, onShowMore = _a.onShowMore, onItemPress = _a.onItemPress, onItemRemove = _a.onItemRemove, openingEntity = _a.openingEntity;
    var isReadOnly = (_b = component.readonly) !== null && _b !== void 0 ? _b : false;
    var count = entities.length;
    var renderShowMore = count > RelatedEntityList_1.MAX_CARDS_TO_SHOW;
    var remainingCount = count - RelatedEntityList_1.MAX_CARDS_TO_SHOW;
    return (react_1.default.createElement(components_1.MBView, null,
        react_1.default.createElement(ColumnHeaders, null),
        react_1.default.createElement(components_1.MBView, null, items.slice(0, RelatedEntityList_1.MAX_CARDS_TO_SHOW).map(function (item) { return (react_1.default.createElement(RelatedQuantityItem, { key: item.id, isReadOnly: isReadOnly, onItemPress: onItemPress, onItemRemove: onItemRemove, openingEntity: openingEntity, item: item, entities: entities, openPicker: openPicker, updateItemQuantity: updateItemQuantity })); })),
        !isReadOnly && react_1.default.createElement(AddMoreButton, { component: component, openPicker: openPicker }),
        renderShowMore && (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onShowMore },
            react_1.default.createElement(components_1.MBView, { style: styles.showMore },
                react_1.default.createElement(components_1.MBTextBody2, null, (0, i18n_1.i18n)('card.blocks.cardsInput.overflow', {
                    count: remainingCount,
                })))))));
}
function ColumnHeaders() {
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(components_1.MBView, { row: true },
        react_1.default.createElement(components_1.MBView, { style: { width: (0, components_1.grid)(5) } },
            react_1.default.createElement(components_1.MBTextOverline, { align: "center", color: colors.input.label }, (0, i18n_1.i18n)('card.blocks.cardsInput.quantity.header.quantity'))),
        react_1.default.createElement(components_1.MBView, { paddingLeft: 2 },
            react_1.default.createElement(components_1.MBTextOverline, { color: colors.input.label }, (0, i18n_1.i18n)('card.blocks.cardsInput.quantity.header.name')))));
}
function RelatedQuantityItem(_a) {
    var _b, _c;
    var item = _a.item, entities = _a.entities, updateItemQuantity = _a.updateItemQuantity, isReadOnly = _a.isReadOnly, onItemPress = _a.onItemPress, onItemRemove = _a.onItemRemove, openingEntity = _a.openingEntity;
    var _d = (0, react_1.useState)(String((_b = item.quantity) !== null && _b !== void 0 ? _b : 0)), value = _d[0], setValue = _d[1];
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var entity = entities.find(function (e) { return e.id === item.id; });
    (0, react_1.useEffect)(function () {
        var number = parseValue(value);
        if (!isNaN(number)) {
            updateItemQuantity(item.id, number);
        }
    }, [item.id, updateItemQuantity, value]);
    var onBlur = (0, react_1.useCallback)(function () {
        var number = parseValue(value);
        if (isNaN(number)) {
            setValue('0');
        }
        else {
            setValue(String(number));
        }
    }, [value, setValue]);
    return (react_1.default.createElement(components_1.MBView, { style: styles.cardRow },
        react_1.default.createElement(components_1.MBTextInput, { value: value, onChangeText: setValue, onBlur: onBlur, size: "normal", keyboardType: "number-pad", noPadding: true, style: [styles.input, { borderColor: colors.input.borderInactive }], editable: !isReadOnly }),
        react_1.default.createElement(Pill_1.Pill, { sections: [(_c = entity === null || entity === void 0 ? void 0 : entity.fields) === null || _c === void 0 ? void 0 : _c.name], maxWidth: 250, onPress: !(0, lodash_1.isNil)(onItemPress) ? function () { return onItemPress(entity); } : undefined, onRemove: !(0, lodash_1.isNil)(onItemRemove) ? function () { return onItemRemove(entity); } : undefined, loading: (entity === null || entity === void 0 ? void 0 : entity.id) === (openingEntity === null || openingEntity === void 0 ? void 0 : openingEntity.id) })));
}
function AddMoreButton(_a) {
    var openPicker = _a.openPicker;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(react_native_1.TouchableOpacity, { onPress: openPicker, style: { paddingVertical: (0, components_1.grid)(0.75) } },
        react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true },
            react_1.default.createElement(components_1.MBIconV2, { name: "plus-circle", size: (0, components_1.grid)(3.5), color: colors.foregroundInactive, style: { marginRight: (0, components_1.grid)(0.25) } }),
            react_1.default.createElement(components_1.MBTextBody2, null, (0, i18n_1.i18n)('card.blocks.cardsInput.addMore')))));
}
function parseValue(value) {
    var cleanedValue = value.replace(/\D/g, '');
    return parseInt(cleanedValue, 10);
}
var styles = react_native_1.StyleSheet.create({
    input: {
        height: (0, components_1.grid)(3.5),
        width: (0, components_1.grid)(5),
        textAlign: 'center',
        borderRadius: (0, components_1.grid)(0.5),
        marginTop: (0, components_1.grid)(0.75),
        marginRight: (0, components_1.grid)(0.5),
    },
    cardRow: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        marginBottom: (0, components_1.grid)(0.5),
    },
    showMore: {
        marginLeft: (0, components_1.grid)(1),
        marginRight: (0, components_1.grid)(1),
    },
});
